<!--
This is a component that shows a password prompt and emits a supplied event to check what function should be
run on pressing the confirm button
USAGE:
PasswordPrompt(
      title="", // title of prompt
      message="", // message of prompt. accepts HTML
      :model="", // boolean value that decides if the prompt should open or not
      :loading="", // boolen value for the button spinner
      @password-ok="", // method to run if password is correct
      @closed=""
      ) // this should contain code to set the variable in :model to false

EXAMPLE:
PasswordPrompt(
      title="Account Reset",
      message="Are you sure that you want to reset <strong>this account?</strong>",
      :model="resDialog",
      :loading="passwordPromptButtonLoading",
      @password-ok="resetUser(methodArgs.id, methodArgs.email)",
      @closed="resDialog = false")
 -->
<template lang="pug">
  v-dialog(v-model="model", width="600" )
    v-card
      v-card-title.headline.grey.lighten-2 {{ title }}
      div.pa-5
        p(v-if="userEmail != null").font-weight-light Are you sure do you want to change the access of <strong>{{ userEmail }}</strong> from user to <strong>admin</strong>?
        p(v-else).font-weight-light.ml-1 <span v-html="message"></span>
        p.font-weight-light.text-center Please enter the CSLib password for confirmation.
        v-text-field.mx-5(dense filled placeholder="Please input password." type="password" v-model="adminPassword"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" v-if="invalid === false" )
        v-text-field.mx-5(dense filled placeholder="Please input password." type="password" v-model="adminPassword"
          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :type="show ? 'text' : 'password'" @click:append="show = !show" :rules="[rules.password]" error v-else)
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="grey", text, @click="adminPassword=null; $emit('closed')").mr-7 Cancel
        v-btn(color="primary" @click="emitEvent" :loading="loading" v-if="userEmail == null" ).mr-5 Confirm
</template>
<script>
export default {
  name: "PasswordPrompt",
  props: {
    title: String,
    message: String,

    // the model prop decides what variable to watch for the prompt to show
    model: Boolean,

    // userEmail prop is only used for admin dialog; otherwise, it is unused
    userEmail: String,

    // used to check if spinner should appear in the confirm button
    loading: Boolean,
  },
  data() {
    return {
      adminPassword: "",

      // used to hide password on the textbox
      show: false,

      // used to check if password is invalid
      invalid: false,
      rules: {
        password: () => `The password is incorrect.`,
      },
    };
  },
  methods: {
    // this function emits the event if password is correct, else, error out
    emitEvent: function () {
      // password is coded here
      if (this.adminPassword === this.$store.state.password) {
        this.invalid = false;
        this.$emit("password-ok");
        this.adminPassword = "";
      } else {
        this.invalid = true;
        this.flashMessage.error({
          title: "Wrong Password",
          message: "Request denied! You entered wrong password.",
          icon: "https://image.flaticon.com/icons/png/512/458/458594.png",
        });
      }
    },
  },
};
</script>
