<template lang="pug">
v-container
    FlashMessage(
      :position="'left bottom'",
      :strategy="'multiple'",
      style="z-index: 99"
    )
    v-row
      v-col(cols="12")
        v-card(flat, outlined)
          v-tabs(
            color="white",
            center-active,
            dark,
            flat,
            background-color="rgba(2, 0, 115, 0.8)",
            v-model="tab",
            prominent
            grow
          )
            v-tabs-slider(color="grey")
            v-tab Validate Accounts
            v-tab Renew Accounts

          v-tabs-items(v-model="tab")
            //- Validate accounts
            v-tab-item
              v-row.mt-5
                v-col(cols="9")
                  v-text-field(v-model="query" dense filled clearable clear-icon="mdi-close-circle" prepend-inner-icon="mdi-magnify").ml-7
                v-col(cols="2")
                  v-btn(
                    color="error"
                    @click="deleteDialog = true"
                    :loading="loading17"
                  ).py-5
                    v-icon.mr-2 mdi-delete
                    span Delete All Requests

              ais-instant-search(
                :search-client="searchClient",
                index-name="csUsers2",
                v-if="searchClient!=null"
                )
                ais-configure(
                  :query="query",
                  hitsPerPage = 10
                  :facetFilters="['waiting:true', 'valid:false']"
                  )
                  ais-hits(ref="aisHit")
                    div(slot-scope="{ items }").pb-5
                      v-alert(
                        border="left"
                        colored-border
                        dense
                        :color="borderColor(user.institution)"
                        v-for="(user, i) in items",
                        :key="user.id",
                        max-width="1100"
                        style="border: .5px solid grey;"
                        tile
                        ).mx-auto
                        v-list
                          span(v-if="user.institution && user.courses")
                            v-chip.font-weight-light.mr-2.ml-2(small dark label :color="chipColor(user.institution)") {{user.institution}}
                            span(v-for="course in user.courses")
                              v-chip.font-weight-light.mr-2(small) {{course}}
                          span(v-else).caption.muted.ml-3 No college unit and courses indicated yet
                          v-list-item(:key="user.email")
                            v-list-item-content
                              v-list-item-title.font-weight-medium {{ user.name }}
                              div.caption.muted {{ user.number }}
                            v-list-item-content
                              v-list-item-title.body-2 {{ user.email }}
                              div.subtitle-2 {{ user.type }}
                            v-list-item-content
                              v-list-item-title.body-2 {{(new Date(user.timestamp)).toString().split(" GMT")[0]}}
                              div.caption.muted(v-if="user.timestamp") {{ timePassed(user.timestamp) }}
                            v-btn.mr-3(
                              color="primary",
                              @click="validateDialog.splice(i, 1, true)",
                              :loading="loading && user.id == index",
                              v-if="role === 'User'"
                              small
                              ) Validate
                            v-btn.mr-3(
                              color="success",
                              :loading="loading1 && user.id == index",
                              @click="adminBox(user.id, user.email); index = user.id",
                              v-else
                              small
                            ) Validate*
                            select.form-control(v-model="role")
                              option(value="User", selected) as User
                              option(value="Admin") as Admin

                            PasswordPrompt(
                              title="Validate Account Request",
                              message="Are you sure that you want to validate <b>this request</b>?",
                              :model="validateDialog[i]",
                              :loading="passwordPromptButtonLoading",
                              @password-ok="validateUser(user.id, user.type, user.email, i); index = user.id",
                              @closed="validateDialog.splice(i, 1, false)"
                            )
                            v-btn.ml-4(
                              color="#8b0000"
                              dark
                              @click="deleteDialog1.splice(i, 1, true);"
                              :loading="loading21"
                              small
                              )
                              span Delete
                              // delete Dialog
                            PasswordPrompt(
                              title="Delete Account Request",
                              :message="'Are you sure that you want to delete registration request by user <b>'+user.name+'</b>?'",
                              :model="deleteDialog1[i]",
                              :loading="passwordPromptButtonLoading",
                              @password-ok="deleteUser(user.id, i)",
                              @closed="deleteDialog1.splice(i, 1, false)"
                            )
                      h3(v-if="items.length == 0 && query.length != 0").font-weight-light.text-muted.text-center No matched user for the query <strong>"{{query}}"</strong>.
                      h3(v-else-if="items.length == 0").font-weight-light.text-muted.text-center No users to validate
                  ais-pagination(pages="5")
                    div(slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}")
                      .text-center.mb-7(v-if="pages.length >= 2")
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-if="!isFirstPage",
                          @click.prevent="refine(0)",
                          outlined
                        )
                          v-icon mdi-chevron-double-left
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          @click.prevent="refine(currentRefinement - 1)",
                          outlined
                        )
                          v-icon mdi-menu-left
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-for="page in pages",
                          :key="page",
                          :outlined="page != currentRefinement",
                          @click.prevent="refine(page)"
                        ) {{ page + 1 }}
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-if="!isLastPage",
                          @click.prevent="refine(currentRefinement + 1)",
                          outlined
                        )
                          v-icon mdi-menu-right
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-if="!isLastPage",
                          @click.prevent="refine(nbPages)",
                          outlined
                        )
                          v-icon mdi-chevron-double-right
              div.text-center(v-else).pb-10
                  v-progress-circular.text-center.mt-15.mb-15(
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate)

            //- Renew Accounts
            v-tab-item
              v-row(justify="center").mt-5
                v-col(cols="9")
                  v-text-field(v-model="query" dense filled clearable clear-icon="mdi-close-circle" prepend-inner-icon="mdi-magnify").ml-7
              ais-instant-search(
                :search-client="searchClient",
                index-name="csUsers2",
                v-if="searchClient!=null"
                )
                ais-configure(
                  :query="query",
                  hitsPerPage = 10
                  :facetFilters="['waiting:true', 'valid:true']"
                  )
                  PasswordPrompt(
                    title="Renew User",
                    message="Are you sure that you want to renew this user's access?",
                    :model="renewDialog",
                    :loading="passwordPromptButtonLoading",
                    @password-ok="renewUser",
                    @closed="renewDialog = false"
                  )
                  ais-hits(ref="aisHit")
                    div(slot-scope="{ items }").pb-5
                      v-alert(
                        border="left"
                        colored-border
                        dense
                        :color="borderColor(user.institution)"
                        v-for="(user, i) in items",
                        :key="user.id",
                        max-width="1100"
                        style="border: .5px solid grey;"
                        tile
                        ).mx-auto
                        v-list
                          span(v-if="user.institution && user.courses")
                            v-chip.font-weight-light.mr-2.ml-2(small dark label :color="chipColor(user.institution)") {{user.institution}}
                            span(v-for="course in user.courses")
                              v-chip.font-weight-light.mr-2(small) {{course}}
                          span(v-else).caption.muted.ml-3 No college unit and courses indicated yet
                          v-list-item(:key="user.email")
                            v-list-item-content
                              v-list-item-title.font-weight-medium {{ user.name }}
                              div.caption.muted {{ user.number }}
                            v-list-item-content
                              v-list-item-title.body-2 {{ user.email }}
                              div.subtitle-2 {{ user.type }}
                            v-list-item-content
                              v-list-item-title.body-2 {{(new Date(user.timestamp)).toString().split(" GMT")[0]}}
                              div.caption.muted(v-if="user.timestamp") {{ timePassed(user.timestamp) }}
                            v-btn.mr-3(
                              color="info",
                              @click="renewUserDialog(user.id, user.email)",
                              small
                              ) Renew
                            v-btn.ml-4(
                              color="#8b0000"
                              dark
                              @click="deleteDialog2.splice(i, 1, true);"
                              :loading="loading21"
                              small
                              )
                              span Delete
                              // delete Dialog
                            PasswordPrompt(
                              title="Delete Account Request",
                              :message="'Are you sure that you want to delete account renewal request by user <b>'+user.name+'</b>?'",
                              :model="deleteDialog2[i]",
                              :loading="passwordPromptButtonLoading",
                              @password-ok="deleteUser(user.id, i)",
                              @closed="deleteDialog2.splice(i, 1, false)"
                            )
                      h3(v-if="items.length == 0 && query.length != 0").font-weight-light.text-muted.text-center No matched user for the query <strong>"{{query}}"</strong>.
                      h3(v-else-if="items.length == 0").font-weight-light.text-muted.text-center No users to renew
                  ais-pagination(pages="5")
                    div(slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}")
                      .text-center.mb-7(v-if="pages.length >= 2")
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-if="!isFirstPage",
                          @click.prevent="refine(0)",
                          outlined
                        )
                          v-icon mdi-chevron-double-left
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          @click.prevent="refine(currentRefinement - 1)",
                          outlined
                        )
                          v-icon mdi-menu-left
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-for="page in pages",
                          :key="page",
                          :outlined="page != currentRefinement",
                          @click.prevent="refine(page)"
                        ) {{ page + 1 }}
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-if="!isLastPage",
                          @click.prevent="refine(currentRefinement + 1)",
                          outlined
                        )
                          v-icon mdi-menu-right
                        v-btn.mx-1(
                          color="primary",
                          x-small,
                          fab,
                          v-if="!isLastPage",
                          @click.prevent="refine(nbPages)",
                          outlined
                        )
                          v-icon mdi-chevron-double-right
              div.text-center(v-else).pb-10
                  v-progress-circular.text-center.mt-15.mb-15(
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate)
    PasswordPrompt(
      title="Delete All Account Requests",
      message="Are you sure that you want to delete <b>ALL account requests</b>?",
      :model="deleteDialog",
      :loading="passwordPromptButtonLoading",
      @password-ok="deleteAllUsers",
      @closed="deleteDialog = false"
    )
    PasswordPrompt(
        title="Validate as Admin",
        message="Are you sure that you want to give <strong>this user</strong> the role and access of an <strong>admin</strong>?",
        :model="adminDialog",
        :loading="passwordPromptButtonLoading",
        @password-ok="addAdminRole(userId, userEmail)",
        @closed="adminDialog = false"
      )
</template>
<script>
import firebase from "firebase/app";
import "firebase/functions";

let deleteUser = firebase.functions().httpsCallable("deleteUser");
let deleteAllUsers = firebase.functions().httpsCallable("deleteAllUsers");
let validateUser = firebase.functions().httpsCallable("validateUser");
let addAdminRole = firebase.functions().httpsCallable("addAdminRole");
let renewApprove = firebase.functions().httpsCallable("renewApprove");
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import syllabi from "@/syllabi.js";
import PasswordPrompt from "@/components/PasswordPrompt.vue";

export default {
  components: { PasswordPrompt },
  data() {
    return {
      searchClient: null,
      tab: null,
      tab2: null,
      role: "User",
      dialog: false,
      dialog1: false,
      loading: false,
      loading1: false,

      loading17: false,
      loading18: false,
      loading19: false,
      loading21: false,
      deleteDialog1: new Array(10).fill(false),
      deleteDialog2: new Array(10).fill(false),
      deleteDialog: false,
      validateDialog: new Array(10).fill(false),
      adminDialog: false,
      renewDialog: false,
      noDocs: false,
      show: false,
      user: "",
      adminPassword: "",
      userEmail: "",
      userType: "",
      userId: "",
      query: "",
      institution: syllabi.institutes,
      passwordPromptButtonLoading: false,
    };
  },

  async created() {
    this.reloadSearch();
  },

  methods: {
    getNumber() {
      console.log("AAnthon");
    },
    chipColor(userInstitution) {
      if (userInstitution) {
        for (let i = 0; i < this.institution.length; i++) {
          if (this.institution[i].name == userInstitution) {
            return this.institution[i].color;
          }
        }
        return "#656565";
      }
    },
    borderColor(userInstitution) {
      if (userInstitution) {
        if (this.chipColor(userInstitution) != "#656565") {
          return "#34338f";
        }
        return "#D3D3D3";
      }
      return "white";
    },
    timePassed(unix) {
      const diff = Date.now() - unix;
      if (diff / 31556926000 >= 1) {
        return Math.round(diff / 31556926000) + " year/s ago";
      }
      if (diff / 2629743000 >= 1) {
        return Math.round(diff / 2629743000) + " month/s ago";
      }
      if (diff / 86400000 >= 1) {
        return Math.round(diff / 86400000) + " day/s ago";
      }
      console.log(unix);
      if (diff / 3600000 >= 1) {
        return Math.round(diff / 3600000) + " hour/s ago";
      }
      return "less than an hour";
    },
    reloadSearch() {
      this.searchClient = null;
      setTimeout(() => {
        const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter(
          {
            server: {
              apiKey: "9QA76PeXsGdUiQ4Wi0Yrqcw2ougXot0N", // Be sure to use an API key that only allows search operations
              // apiKey: "ZvU5apnwttha8KTbcEskXZcHeIyRF515",
              nodes: [
                {
                  host: "ehubstorage.cslib.upd.edu.ph",
                  port: "443",
                  protocol: "https",
                },
              ],
              cacheSearchResultsForSeconds: 0, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
            },
            additionalSearchParameters: {
              queryBy: "name, number, email",
              sortBy: "timestamp:asc",
            },
          }
        );
        this.searchClient = typesenseInstantsearchAdapter.searchClient;
      }, 1500);
    },
    renewUserDialog(id, email) {
      this.userEmail = email;
      this.userId = id;
      this.renewDialog = true;
    },
    async renewUser() {
      this.passwordPromptButtonLoading = true;
      await renewApprove({ id: this.userId }).then(() => {
        this.flashMessage.success({
          title: "Renew User Access Successfully",
          message: "You successfully renewed " + this.userEmail + "'s access",
          icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
        });
      });
      this.passwordPromptButtonLoading = false;
      this.renewDialog = false;
      this.reloadSearch();
    },
    async deleteAllUsers() {
      this.loading19 = true;
      this.passwordPromptButtonLoading = true;

      await deleteAllUsers().then(() => {
        this.role = "Admin";
        this.flashMessage.success({
          title: "Deleted All Account Requests",
          message: "You successfully deleted all account requests.",
        });
      });

      this.passwordPromptButtonLoading = false;
      this.deleteDialog = false;
      this.reloadSearch();

      this.loading19 = false;
    },

    async deleteUser(id, index) {
      this.passwordPromptButtonLoading = true;
      deleteUser({ id: id }).then(() => {
        this.flashMessage.error({
          title: "Request Was Deleted",
          message: "You successfully deleted the account request.",
          icon: "https://png.pngtree.com/png-vector/20190916/ourmid/pngtree-block-icon-for-your-project-png-image_1731069.jpg",
        });
        this.deleteDialog1.splice(index, 1, false);
        this.deleteDialog2.splice(index, 1, false);
        this.passwordPromptButtonLoading = false;
        this.reloadSearch();
      });
    },

    async validateUser(uid, type, email, index) {
      this.loading = true;
      this.passwordPromptButtonLoading = true;
      await validateUser({ uid }).then(() => {
        this.loading = false;
        this.flashMessage.success({
          title: "Validated User Successfully",
          message: "You successfully validated " + email + " as user!",
          icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
        });
      });
      this.passwordPromptButtonLoading = false;
      this.validateDialog.splice(index, 1, false);
      this.loading = false;
      this.reloadSearch();
    },
    async adminBox(id, email) {
      this.loading1 = true;
      this.userEmail = email;
      this.userId = id;
      this.adminDialog = true;
      this.loading1 = false;
    },

    async addAdminRole(uid, email) {
      this.passwordPromptButtonLoading = true;

      await addAdminRole({ uid }).then(() => {
        this.role = "Admin";
        this.flashMessage.success({
          title: "Set User to Admin Successfully",
          message:
            "You successfully change " +
            email +
            "'s access from user to admin!",
          icon: "https://www.kindpng.com/picc/m/225-2258183_transparent-solution-icon-png-success-icon-transparent-png.png",
        });
      });
      this.passwordPromptButtonLoading = false;
      this.adminDialog = false;
      this.reloadSearch();
    },
  },
};
</script>
<style>
select.form-control {
  width: 10% !important;
}

.v-card {
  z-index: 0;
}

._vue-flash-msg-body {
  z-index: 99 !important;
}

.vue-flash-msg-body__content {
  padding: 5px !important;
  z-index: 99 !important;
}

._vue-flash-msg-body__title {
  font-weight: bold;
  margin-top: 9px;
  font-size: 20px;
  padding-right: 10px;
  font-family: sans-serif;
  z-index: 99 !important;
}

._vue-flash-msg-body__text {
  margin-top: -15px;
  font-family: sans-serif;
  padding-right: 10px;
  z-index: 99 !important;
}
</style>
